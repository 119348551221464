import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`My teacher told me to write a sonnet,`}<br parentName="p"></br>{`
`}{`A sonnet about passion of some kind.`}<br parentName="p"></br>{`
`}{`Stare at my notes, only scribbles on it`}<br parentName="p"></br>{`
`}{`Stare at my ceiling, nothing comes to mind.`}</p>
    <p>{`“Darling, now go to bed,” hear my mom say`}<br parentName="p"></br>{`
`}{`I slam my laptop shut, flump into bed.`}<br parentName="p"></br>{`
`}{`I close my eyes, block my ears, shut away`}<br parentName="p"></br>{`
`}{`With them I shut this world out of my head.`}</p>
    <p>{`Until all that’s left is dark and silence`}<br parentName="p"></br>{`
`}{`Feels like I’ve jumped off my seesaw of life`}<br parentName="p"></br>{`
`}{`On it, I struggled to find my balance`}<br parentName="p"></br>{`
`}{`Off it, I’m free, I’m a bird in wildlife`}</p>
    <p>{`I think I have passion for nothingness`}<br parentName="p"></br>{`
`}{`All pain’s left behind the world of nothingness`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      